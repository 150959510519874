import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import tweet from "../images/tweet-black.svg"
import fb from "../images/fb-black.svg"
import ln from "../images/ln-black.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const StyledWrapper = styled.div`
  .left-sidebar {
    left: ${(props) => props.theme["zero"]};
    overflow: scroll;
  
    @media (max-width: 450px) {
      top: 85px;
    }

    @media (min-width: 450px) and (max-width: 550px){
      top: 90px;
    }

    @media (min-width: 550px) and (max-width: 599px){
      top: 100px;
    }

    @media (min-width: 600px) and (max-width: 991px) {
      top: 105px;
    }
  }

  .header-link-divider {
    border-bottom: 1px solid ${(props) => props.theme["leftSidebarLineDivider"]};
    max-width: 250px;
    margin-left: ${(props) => props.theme["pixel-50"]};
  }

  .sidebar-internal-links, .sidebar-external-links {
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 50px;
    text-decoration: none;
  }

  .sm-black {
    width: 35px;
    height: 35px;
  }

  .sm-all-icons {
    margin-left: 25px; 
  }

  .active {
    border-bottom: 2px solid #464FD0;
  }

`;

const SidebarLeft = [
  {
    topLinks: [
      { navLink: "Online Guide", href:"/#onlineguide", color: "black",isAnchor:true},
      { navLink: "Videos", href:"/#videos", color: "black",isAnchor:true },
      { navLink: "Live Webinars", href:"/live-webinars",color: "black",isAnchor:true },
      { navLink: "Register", href:"/#register",color: "black",isAnchor:true },
      { navLink: "Teachers", href:"/#teachers", color: "black",isAnchor:true },
      { navLink: "About", href:"/#about", color: "black",isAnchor:true },
      { navLink: "Contact", href:"/#contact", color: "black",isAnchor:true },
    ],
  },
  {
    BottomLinks: [
      { navLink: "Latest", href:"/latest" },
      { navLink: "Press Releases", href:"/press-releases" },
      { navLink: "News", href:"/news" },
      { navLink: "Work For Us", href:"/work-for-us" },
      { navLink: "Vacancies", href:"/vacancies" },
    ],
  },
];

const LeftSidebar = ({ setLeftSidebar, leftSidebar, setRightSidebar, rightSidebar }) => {

  return (
  <StyledWrapper>
    <nav className="container-fluid left-sidebar sidebar">
      <div className="row px-4 mt-5">
        {SidebarLeft[0].topLinks.map((link) => (
          <div className="col-12 last-child-donate">
            { link.isAnchor ?
                <AnchorLink className="sidebar-internal-links" to={link.href} onAnchorLinkClick={() => setLeftSidebar(!leftSidebar)}>
                  <p style={{ color: link.color }}>{link.navLink}</p>
                </AnchorLink>
                :
                <Link className="sidebar-internal-links" to={link.href}>
                  <p style={{ color: link.color }}>{link.navLink}</p>
                </Link>
            }

          </div>
        ))}
      </div>
      <div className="row mt-5 sm-all-icons">
        <Link to="">
        <img className="sm-black" src={tweet} alt=""/>
        </Link>
        <Link to="">
        <img className="sm-black mx-3" src={fb} alt=""/>
        </Link>
        <Link to="">
        <img className="sm-black" src={ln} alt=""/>
        </Link>
      </div>
    </nav>
  </StyledWrapper>
);
};

export default LeftSidebar;
