import React from "react";
import styled from "styled-components";
import tweet from "../images/SMLogos/7.svg";
import fb from "../images/SMLogos/8.svg";
import ln from "../images/SMLogos/9.svg";
import ig from "../images/SMLogos/10.svg";
import email from "../images/SMLogos/email-SM.svg";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ReactGA from "react-ga";
import tk from "../images/SMLogos/11.svg";

const StyledWrapper = styled.div`
  color: ${props => props.theme["secondaryColor"]};
  background-color: ${props => props.theme["borderBottomColor"]};

  .firstmain-byline {
    font-size: 14px;
    color: white;

    a,
    a:hover,
    a:visited {
      text-decoration: none;
      color: white;
    }
  }

  .footer-links {
    font-size: ${props => props.theme["FooterLinkSize"]};
    line-height: ${props => props.theme["footerLinkLineHeight"]};
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .footer-links:hover {
    color: ${props => props.theme["secondaryColor"]};
  }

  .link-header {
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 600px) {
      font-size: 15px;
      font-weight: bold;
    }
  }

  .link-header-border {
    box-sizing: border-box;
    border: 1px solid ${props => props.theme["secondaryColor"]};
  }

  .link-header2 {
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 15px;
    }
    text-transform: uppercase;
  }

  .head-to-head {
    color: white;
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  .sm-logo {
    width: 60px;
    padding: 15px;
    @media (max-width: 900px) {
      width: 51px;
    }
  }

  .upreach-phn-number {
    color: white;
    text-decoration: underline;
  }

  .col-first {
    font-size: 16px;
    text-transform: uppercase;
    line-height: normal;
    margin-bottom: 15px;
  }

  .inverted-col {
    @media (max-width: 600px) {
      flex-direction: column-reverse;
    }
  }
  .logos{
    @media (max-width: 600px) {
      width: 110%;
    }
  }
`;

const Footer = () => {
  const handleSafe = () => {
    ReactGA.event({
      category: "Footer",
      action: "Safeguarding Policy Button Click",
    });
  };

  return (
    <StyledWrapper>
      <div className="footer container-fluid padding-left-8 padding-right-8 px-4 pt-5">
        <div className="row inverted-col">
          <div className="col-12 col-md-6 col-lg-3 mb-4 mb-md-0">
            <p className="link-header">© 2022 upReach, All Rights Reserved. </p>
            <div className="empty__head__col">
              <a
                className="head-to-head"
                target="_blank"
                href="/privacy-policy"
              >
                <div className="col-first">Privacy Policy & Cookie Policy</div>
              </a>

              <div>
                <Link
                  className="head-to-head"
                  to="https://cms.upreach.org.uk/uploads/up_Reach_Associate_Safeguarding_Policy_and_Procedure_665a696bf5.pdf"
                >
                  <div className="col-first" onClick={handleSafe}>
                    Safeguarding Policy
                  </div>
                </Link>
              </div>
              <div>
                <Link className="head-to-head" to="http://eepurl.com/hGZHwH">
                  <div className="col-first">SUBSCRIBE</div>
                </Link>
              </div>
            </div>
            <div className="pt-4">
              <p className="link-header mb-1">TELEPHONE</p>
              <a className="upreach-phn-number" href="tel:01618832594">
               01618832594
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0">
            <p className="link-header mb-1">OFFICES</p>
            <hr className="link-header-border mb-1 mb-md-3" />
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <p className="link-header mb-1">LONDON</p>
                {/* <p>
                  Canopi
                  <br />
                  7-14 Great Dover Street
                  <br />
                  London SE1 4YR
                </p> */}
                <p>
                Second Home,
                <br />
                68-80 Hanbury Street
                <br />
                London, E1 5JL
              </p>
              </div>
              <div className="col-sm-12 col-lg-6">
                <p className="link-header mb-1">MANCHESTER</p>
                <p>
                  Waulk Mill, Bengal Street, <br />
                  Manchester, M4 6LN
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6">
                <p className="link-header2 mb-1">NOTTINGHAM</p>
                <p>
                  Cubo, Fenchurch House, 12 King St, <br />
                  Nottingham NG1 2AS
                </p>
              </div>
              <div className="col-sm-12 col-lg-6">
                {/* <p className="link-header2 mb-1">Bristol</p>
                <p>
                  Framework, 35 King Street, <br />
                  Bristol, BS1 4DZ
                </p> */}
                <div>
                <p className="link-header mb-2">Bristol</p>
                <p>
                Floor 8, Office 843, <br />
                Spaces Castle Park Programme, <br />
                The Pithay, Bristol BS1 2NB
                </p>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6">
                <p className="link-header2 mb-1">Newcastle</p>
                <p>
                  The Racquets Court, 3 College St, <br />
                  Newcastle upon Tyne NE1 8JG
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="logos">
        <div className="row">
          <div className="col-12">
            <div className="mt-1">
              <a href="mailto:aspire@upreach.org.uk">
                <img className="sm-logo pl-0" src={email} alt="" />
              </a>
              <a target="_blank" href="https://twitter.com/up_Reach">
                <img className="sm-logo pl-0" src={tweet} alt="" />
              </a>
              <a target="_blank" href="https://www.facebook.com/upReachUK/">
                <img className="sm-logo pl-0" src={fb} alt="" />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/upreach/"
              >
                <img className="sm-logo pl-0" src={ln} alt="" />
              </a>
              <a target="_blank" href="https://www.instagram.com/upreach_team/">
                <img className="sm-logo pl-0" src={ig} alt="" />
              </a>
              <a target="_blank" href="https://www.tiktok.com/@upreach_team">
                <img className="sm-logo pl-0" src={tk} alt="" />
              </a>
            </div>
          </div>
        </div>
        </div>
        <div className="mt-0 d-md-flex pb-2">
          <p className="firstmain-byline">
            Website built by
            <a
              href="https://1stmain.co"
              target="_blank"
              rel="noopener nofollow"
            >
              {" "}
              1st Main
            </a>
          </p>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Footer;
