import React, {useState, useEffect}  from "react";
import styled from "styled-components";
import Logo from "../images/logo.svg";
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ReactGA from "react-ga";

const StyledWrapper = styled.div`
  .external-links {
    background-color: ${(props) => props.theme["borderBottomColor"]};
    text-align: center;
  }

  .header-external-links {
    margin-top: ${(props) => props.theme["pixel-20"]};
    font-size: 12px;
    color: ${(props) => props.theme["externalLinkColor"]};
    font-weight: normal;
    text-transform: uppercase;
  }

  .transform-sm {
    text-transform: unset;
  }

  .border-right {
    border-right: 1px solid ${(props) => props.theme["primaryColor"]} !important;
    height:30px;
  }

  .upreach {
    color: ${(props) => props.theme["secondaryColor"]};
    // font-size: ${(props) => props.theme["upreachNameHeader"]};
    font-weight: ${(props) => props.theme["footerLinkBold"]};
    font-size:12px;
    padding-top:10px;
  }

  .grid-upper-container {
    display: grid !important;
    grid-template-columns: ${(props) => props.theme["percent-25"]} auto auto auto auto auto;
  }

  .grid-lower-container {
    display: grid !important;
    grid-template-columns: ${(props) => props.theme["percent-25"]} auto;
  }

  .internal-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    font-size: ${(props) => props.theme["headerLinkSize"]};
    font-weight: ${(props) => props.theme["headingBold"]};
  }

  .internal-links-a-tag.donate {
    color: ${(props) => props.theme["borderBottomColor"]};
  }

  .internal-links-a-tag {
    color: #5DB9DB;
    position: relative;
    top: 5px;
    width: fit-content;
    margin-left: auto;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.5s;
  }

  .internal-links-a-tag:hover {
    border-bottom: 2px solid #5DB9DB;
    text-decoration: none;
  }

  .active {
    border-bottom: 2px solid #464FD0;
  }

  .header-mobile-desktop-visibility {
    @media (max-width: 1091px) {
      display: none;
    }
  }

  .sticky-nav {
    background-color: #000000;
    width: 100%;
    z-index: 9;
  }

  .upreach-transforming {
    position: relative;
    top: 15px;
  }

  .upreach-program {
    position: relative;
    left: -5px;
  }

  .main__logo {
    max-width: 200px;
    margin-bottom:0px;
  }
  .no-scroll {
    overflow: hidden;
  }
`;

const Header = () => {
  const [fixed, setFixed] = React.useState(80);
  const [position, setPosition] = React.useState(null);
  const [isClicked, setIsClicked] = useState(false);

  const pixelValue = fixed + "px";

  const handleLogoClick = (e) => {
    // if (!isScrolled) {
    //   window.scrollTo({ top: 0 });
    //   setIsScrolled(true);
    // }
    window.scrollTo({ top: 0 });
    e.preventdefault();
  }; 
  useEffect(() => {
    // Add the "no-scroll" class to the body when the component mounts
    document.body.classList.add('no-scroll');

    // Remove the "no-scroll" class when the page has finished loading
    window.addEventListener('load', () => {
      document.body.classList.remove('no-scroll');
    });

    // Cleanup: Remove the event listener on unmount
    return () => {
      window.removeEventListener('load', () => {
        document.body.classList.remove('no-scroll');
      });
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset > 100) {
        setFixed(0);
        setPosition("fixed");
      }
      if (window.pageYOffset < 100) {
        setFixed(80);
        setPosition("unset");
      }
    });
  }, []);

  const handleOnline=()=>{
    ReactGA.event({
      category:"Nav bar",
      action:"Read Button Click",
    });
  }
  const handleVideo=()=>{
    ReactGA.event({
      category:"Nav bar",
      action: "Watch Button Click",
    });
  }
  // const handleRegister=()=>{
  //   console.log("&&&&&&&&&&&&&&&&&&&&&&&&&(((((", document.readyState )
  //   // setIsClicked(true);
  //   // console.log("isClickedisClicked",isClicked)
  //   // isClicked &&
  //   // window.scrollTo({
  //   //   behavior: 'auto'
  //   // }); 
  //   if(document.readyState != 'loading'){
  //   ReactGA.event({
  //     category:"Nav bar",
  //     action: "Register Button Click",
  //   });
  // }
  // }
  const handleRegister = () => {
    // Scroll to the targetElement's top position after a delay
    setTimeout(() => {
      const targetElement = document.querySelector("#register");
      if (targetElement) {
        const topPosition = targetElement.offsetTop;
        window.scrollTo({
          top: topPosition - 50,
          behavior: "smooth",
        });
      }
    }, 2000); 
  };
  const handleTeachers=()=>{
    ReactGA.event({
      category:"Nav bar",
      action: "Teachers Button Click",
    });
  }
  const handleLive=()=>{
    ReactGA.event({
      category:"Nav bar",
      action: "Live Webinars Button Click",
    });
  }
  const handleAbout=()=>{
    ReactGA.event({
      category:"Nav bar",
      action: "About Button Click",
    });
  }
  const handleContact=()=>{
    ReactGA.event({
      category:"Nav bar",
      action: "Contact Button Click",
    });
  }
  let isScrolled = false;
  const handleScroll = () => {
    if (isScrolled) {
      window.removeEventListener('scroll', handleScroll);
    }
    };
    
  return (
    <StyledWrapper>
      <div className="header-mobile-desktop-visibility">
        <div className="grid-upper-container pb-3 external-links">
          <div className="item1">
            <div className="upreach-transforming">
            <p className="header-external-links upreach upreach-program my-0">SIXTH FORM</p>
            </div>
          </div>
          <div className="item2 white-background">
            <a target="_blank" href="https://upreach.org.uk">
              <p className="header-external-links border-right px-3 d-flex justify-content-center align-items-center">
                UPREACH
              </p>
            </a>
          </div>
          <div className="item3 white-background">
            <a target="_blank" href="http://socialmobilitynetwork.org.uk/">
              <p className="header-external-links border-right px-3 d-flex justify-content-center align-items-center">
                Social Mobility Network
              </p>
            </a>
          </div>
          <div className="item4 white-background">
            <a target="_blank" href="https://realrating.co.uk/">
              <p className="header-external-links border-right px-3 d-flex justify-content-center align-items-center">
                Contextualised recruitment
              </p>
            </a>
          </div>
          <div className="item5 white-background">
            <a target="_blank" href="https://studentsocialmobilityawards.org.uk/">
              <p className="header-external-links border-right px-3 d-flex justify-content-center align-items-center">
                student social mobility awards
              </p>
            </a>
          </div>
          <div className="item6 white-background">
            <a target="_blank" href="https://getemployable.org/">
              <p className="header-external-links px-3 pt-md-1 d-flex justify-content-center align-items-center">
                Graduate Employability Framework
              </p>
            </a>
          </div>
        </div>
        <div
          className="sticky-nav"
          style={{ top: pixelValue, position: position }}
        >
          <div className="grid-lower-container py-4">
            <div className="item1">
              <Link to="/" onClick={handleLogoClick} key={1}>
                <img className="mx-auto d-flex main__logo" src={Logo} alt=""/>
              </Link>
            </div>
            <div className="internal-links pr-1 pr-md-3 pr-lg-5">
              <AnchorLink activeClassName="active" partiallyActive={true}  className="internal-links-a-tag" to="/#onlineguide" onAnchorLinkClick={handleOnline} >
                Online Guide
              </AnchorLink>

              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#videos" onAnchorLinkClick={handleVideo} >
                Videos
              </AnchorLink>

              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/live-webinars" onAnchorLinkClick={handleLive} >
                Live Webinars
              </AnchorLink>

              <AnchorLink activeClassName="active" partiallyActive={true} to="/#register" className="internal-links-a-tag" onAnchorLinkClick={handleRegister}>
                Register 
              </AnchorLink>

              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#teachers" onAnchorLinkClick={handleTeachers}>
                Teachers
              </AnchorLink>

              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#about" onAnchorLinkClick={handleAbout}>
                About
              </AnchorLink>

              <AnchorLink activeClassName="active" partiallyActive={true} className="internal-links-a-tag" to="/#contact" onAnchorLinkClick={handleContact}>
                Contact
              </AnchorLink>

            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Header;